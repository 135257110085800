import React, {useCallback, useState} from 'react';
import {SearchWrap} from "../style";
import {useNavigate, useSearchParams} from "react-router-dom";

const Search = ({apiData}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentSearchType = searchParams.get('searchType') || "";
    const currentSearchText = searchParams.get('searchText') || "";
    const [inputs, setInputs] = useState({
        searchType: currentSearchType ? currentSearchType : '',
        searchText: currentSearchText ? currentSearchText : '',
    });
    const { searchType, searchText } = inputs;
    const selectList = [
        {id: 1, value: "", name: "전체"},
        {id: 2, value: "title", name: "제목"},
        {id: 3, value: "contents", name: "내용"},
    ];
    const onChange = useCallback(
        (e) => {
            const { value, name } = e.target;
            setInputs({
                ...inputs,
                [name]: value,
            });
        },
        [inputs],
    );

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        searchParams.set('pageNo', '1');
        searchType ? searchParams.set('searchType', searchType) : searchParams.delete('searchType');
        searchText ? searchParams.set('searchText', searchText) : searchParams.delete('searchText');
        setSearchParams(searchParams)
    },[inputs, searchParams])



    return (
        <SearchWrap>
            <h4>총 <span>{apiData.totalCount}</span>건</h4>
            <div>
                <form onSubmit={onSubmit}>
                    <select onChange={onChange} name="searchType" value={searchType}>
                        {
                            selectList.map(item => <option key={item.id} value={item.value}>{item.name}</option>)
                        }
                    </select>
                    <div className="searchBox">
                        <input type="text" placeholder="검색" onChange={onChange} name="searchText" value={searchText}/>
                        <button type="submit">검색</button>
                    </div>
                </form>
            </div>
        </SearchWrap>
    );
};

export default Search;