import styled from "styled-components"

export const Section = styled.section`
  .popup_con{
    padding: 0 20px 30px;
    font-size: 14px;
    line-height: 160%;
  }
  .terms_title {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
    line-height: 25px;
    font-size: 24px;
    font-weight: 700;
  }
  .terms_text {
    margin: 20px 0 30px;
  }
  .terms_text_number {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
  }
  .terms_text_underText {
    padding: 10px 0 0 10px;
  }
  .terms_text_list {
    padding: 5px 0 0 25px;
  }
  .terms_subTitle {
    padding: 10px 0 0 35px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
`
export const PopupWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  min-width: 320px;
  background-color: #fff;
  img{
    max-width: 100%;
    &.pc{
      @media screen and (max-width: 750px) {
        display: none;
      }
    }
    &.mb{
      display: none;
      @media screen and (max-width: 750px) {
        display: inline;
      }
    }
  }
  .b-eventClose {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    label {
      font-size: 17px;
      font-weight: 400;
      color: #333;
      display: flex;
      align-items: center;
      input {
        margin-right: 10px;
      }
    }
  }
  .b-eventClose__btn{
    border: 0;
    background-color: transparent;
    background-image: url("/img/icon/img-eventCloseBtn.png");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 10px;
    background-size: 13px;
    cursor: pointer;
    span {
      font-size: 17px;
      font-weight: 400;
      color: #333;
      margin-right: 10px;
    }
  }
  
`