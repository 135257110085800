import styled, {keyframes} from "styled-components"

const menuBar01 = keyframes`
  0% {
    transform : translateY(10px) rotate(45deg);
  }
  50% {
    transform : translateY(10px) rotate(0);
  }
  100% {
    transform : translateY(0) rotate(0);
  }
`
const menuBar02 = keyframes`
  0% {
    transform : translateY(-10px) rotate(-45deg);
  }
  50% {
    transform : translateY(-10px) rotate(0);
  }
  100% {
    transform : translateY(0) rotate(0);
  }
`
const activeMenuBar01 = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(8px) rotate(0);
  }
  100% {
    transform: translateY(8px) rotate(45deg);
  }
`
const activeMenuBar02 = keyframes`
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-10px) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
`
export const HeaderArea = styled.header`
  width: 100%;
  height: 100px;
  padding: 0 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  transition: 0.3s;
  z-index: 999;
  &.bg-w{
    background-color: #fff;
    border-color: #eee;
    a{
      color: #333;
    }
    button span{
      background-color: #858585;
    }
  }
  &.active{
    background-color: #fff;
    border-color: #eee;
    nav:after{
      height: 212px;
    }
    .hideNav{
      z-index: 1;
      height: 210px;
      transition: 0.2s;
    }
    a{
      color: #333;
    }
  }
  @media screen and (max-width: 1370px) {
    padding: 0 40px;
    height: 80px;
  }
  @media screen and (max-width: 600px) {
    padding: 0 30px;
  }
`

export const MenuList = styled.div`
  width: calc(50% - 200px);
  height: 100%;
  margin: 0 100px;
  nav{
    height: 100%;
     & > ul{
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
       & > li{
          text-align: center;
          width: 25%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-sizing: border-box;
          border-bottom: 2px solid transparent;
          margin-top: 2px;
          position: relative;
          a{
            text-decoration: none;
            color: #fff;
            font-weight: 700;
            font-size: 18px;
          }
          &:hover{
            border-bottom: 2px solid #7aa800;
           & > a{
              color: #7aa800;
            }
            .hideNav{
              background-color:#fafafa;
            }
          }
        }
      }
    &:after{
      content:"";
      display: block;
      height: 0px;
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      top: 100px;
      background-color: #fff;
      transition: 0.2s;
      background-image: url("/img/img-navBg.png");
      background-repeat: no-repeat;
      background-position:180px 20px;
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    }
  }
  .hideNav{
    position: absolute;
    top: 100px;
    overflow: hidden;
    transition: 0.2s;
    height: 0px;
    width: 100%;
    padding-top: 15px;
    box-sizing: border-box;
    li {
      &:hover{
        & > a{
          color: #7aa800;
        }
      }
      a{
        width: 100%;
        padding: 15px 0;
        font-size: 16px;
        color: #555;
        display: inline-block;
      }
    }
  }
  @media screen and (max-width: 1370px) {
    display: none;
  }
`
export const NavBg = styled.div`

`
export const MenuTrigger = styled.button`
  @media screen and (max-width: 1370px) {
    display: inline-block;
  }
  position: relative;
  width: 30px;
  height: 20px;
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  border: 0;
  background-color: transparent;
  z-index: 9999;
  display: none;
  &.active{
    span{
      background-color: #858585;
      &:nth-of-type(1){
        animation : ${activeMenuBar01} .75s forwards;
      }
      &:nth-of-type(2){
        opacity : 0;
      }
      &:nth-of-type(3){
        animation : ${activeMenuBar02} .75s forwards;
      }
    }
  }
  span{
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    &:nth-of-type(1){
      top: 0;
      animation : ${menuBar01} .75s forwards;
    }
    &:nth-of-type(2){
      top: 9px;
      transition: all .25s .25s;
      opacity: 1;
    }
    &:nth-of-type(3){
      bottom: 0;
      animation : ${menuBar02} .75s forwards;
    }
  }
`
export const RightMenuWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9997;
  height: 100vh;
  overflow: hidden;
  display: none;
  &.active{
    display: block;
  }
`
export const RightMenu = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: #fff;
  z-index: 9998;
  width: 0px;
  transition: 0.5s;
  &.active{
    width: 400px;
    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }
`
export const RightMenuList = styled.ul`
  margin-top: 80px;
  & > li{
    border-bottom: 1px solid #ddd;
    height: 80px;
    overflow: hidden;
    transition: 0.5s;
    &:nth-of-type(1).active{
      height: 174px;
      @media screen and (max-width: 600px) {
        height: 170px;
      }
    }
    &:nth-of-type(2).active{
      height: 293px;
      @media screen and (max-width: 600px) {
        height: 283px;
      }
    }
    &:nth-of-type(3).active{
      height: 253px;
      @media screen and (max-width: 600px) {
        height: 245px;
      }
    }
    &:nth-of-type(4).active{
      height: 173px;
      @media screen and (max-width: 600px) {
        height: 169px;
      }
    }
    &:first-of-type{
      border-top: 1px solid #ddd;
    }
    &.active{
      button{
        background-image: url("/img/icon/ico-arrow__up.png");
        color: #7aa800;
      }
    }
   button{
     width: 100%;
     height: 80px;
     background-color: transparent;
     border: 0;
     padding: 0 30px;
     background-image: url("/img/icon/ico-arrow__down.png");
     background-repeat: no-repeat;
     text-align: left;
     background-position: 90% center;
     color: #191919;
     font-weight: 600;
     font-size: 24px;
     cursor: pointer;
     @media screen and (max-width: 600px) {
       font-size: 22px;
     }
   }
    ul{
      background-color: #fafafa;
      padding: 25px 30px;
      border-top: 2px solid #7aa800;
      overflow: hidden;
      a{
        padding: 10px 0;
        color: #767676;
        font-size: 20px;
        font-weight: 400;
        display: inline-block;
        width: 100%;
        @media screen and (max-width: 600px) {
          font-size: 18px;
        }
        &:hover, &:focus{
          color: #7aa800;
          font-weight: 500;
        }
      }
    }
  }
`